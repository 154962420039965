import React from "react";
import {
  security,
  withdrawals,
  transparency,
  blue_circle,
  orange_circle,
  blue_red_circle,
  banner2,
} from "../../utils/data/data";

const Rules = () => {
  return (
    <section id="RULES" className="mt-[15vw] xl:mt-[20vw] relative">
      <div className="grid grid-cols-3 gap-10 px-5 sm:px-10 md:px-0">
        <div className="box--blue relative col-span-3 md:col-span-1 flex flex-col gap-6 2xl:px-6 py-6 text-center">
          <div className="text-xl lg:text-3xl font-bold">Security</div>
          <div className="flex justify-center">
            <img src={security} alt="icon" className="w-14 lg:w-auto" />
          </div>
          <div className="px-[20%] text-sm sm:text-base md:text-sm lg:text-base">
            We use state-of-the-art encryption technology to protect your
            personal information and funds.
          </div>
          <img src={blue_circle} alt="icon" className="blur_circle" />
        </div>
        <div className="box--orange relative col-span-3 md:col-span-1 flex flex-col gap-6 2xl:px-6 py-6 text-center">
          <div className="text-xl lg:text-3xl font-bold">Transparency</div>
          <div className="flex justify-center">
            <img src={transparency} alt="icon" className="w-14 lg:w-auto" />
          </div>
          <div className="px-[20%] text-sm sm:text-base md:text-sm lg:text-base">
            Our Lucky-Number based on blockchain is completely transparent and
            fair.
          </div>
          <img src={orange_circle} alt="icon" className="blur_circle" />
        </div>
        <div className="box--blue_red relative col-span-3 md:col-span-1 flex flex-col gap-6 2xl:px-6 py-6 text-center">
          <div className="text-xl lg:text-3xl font-bold">Fast withdrawals</div>
          <div className="flex justify-center">
            <img src={withdrawals} alt="icon" className="w-14 lg:w-auto" />
          </div>
          <div className="px-[20%] text-sm sm:text-base md:text-sm lg:text-base">
            Our withdrawal process is designed to be fast and hassle-free
          </div>
          <img src={blue_red_circle} alt="icon" className="blur_circle" />
        </div>
      </div>
      {/* <img
        src={banner2}
        alt="icon"
        className="absolute top-[60vw] md:top-[43vw] left-0 z-[-1] w-[150%]"
      /> */}
      <img
        src={banner2}
        alt="icon"
        className="absolute top-[-7rem] scale-125 z-[-1] "
      />
    </section>
  );
};

export default Rules;
