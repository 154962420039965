import React, { useState } from "react";
import { logo } from "../../utils/data/data";
import { Link, animateScroll as scroll } from "react-scroll";
import useTutorial from "../../hooks/useTutorial";
import Tutorial from "../Tutorial/Tutorial";

enum NavEnum {
  GAME = "Game",
  TUTORIAL = "Tutorial",
  ABOUT = "About",
  GAMEFLOW = "How it Works",
  CONTRACT = "Contract",
}
const Navbar = () => {
  const [state, setState] = useState(NavEnum.GAME);
  const { onOpen } = useTutorial();

  return (
    <>
      <Tutorial />
      <section id="NAVBAR" className="container mx-auto px-3 md:px-0">
        <div className="relative mt-5 md:mt-14 mb-5 2xl:mb-20">
          <div className="md:absolute top-[-20px] lg:top-[-30px] w-[105px] h-16 lg:h-[103px]">
            <a href="/">
              <img
                src={logo}
                alt="logo"
                className="w-full h-full hover:animate-shake"
              />
            </a>
          </div>
          <div className="flex sm:gap-4 lg:gap-0 justify-evenly md:justify-center lg:justify-evenly items-center mt-5 md:mt-0 text-xs lg:text-base relative z-10 md:pl-[10%] lg:pl-[20%] xl:pl-0">
            <button
              onClick={() => setState(NavEnum.GAME)}
              className={`${state === NavEnum.GAME && "active"} btn `}
            >
              Game
            </button>

            <button
              onClick={() => {
                setState(NavEnum.TUTORIAL);
                onOpen();
              }}
              className={`${state === NavEnum.TUTORIAL && "active"} btn`}
            >
              Tutorial
            </button>
            <Link
              to={"GAMEFLOW"}
              spy={true}
              smooth={true}
              offset={-50}
              duration={1000}
            >
              <button
                onClick={() => setState(NavEnum.GAMEFLOW)}
                className={`${state === NavEnum.GAMEFLOW && "active"} btn`}
              >
                How it works
              </button>
            </Link>
            <a href="https://polygonscan.com/address/0x59aa36afa2f2a14ee06ea6da9f45aee3bd94ed5f">
              <button
                onClick={() => setState(NavEnum.CONTRACT)}
                className={`${state === NavEnum.CONTRACT && "active"} btn`}
              >
                Contract
              </button>
            </a>
            <Link
              to={"ABOUT"}
              spy={true}
              smooth={true}
              offset={-50}
              duration={2500}
            >
              <button
                onClick={() => setState(NavEnum.ABOUT)}
                className={`${state === NavEnum.ABOUT && "active"} btn`}
              >
                About
              </button>
            </Link>

            {/* <div
              className={`absolute group btn active hidden md:block !backdrop-blur-0 transition-all duration-500 !bg-none cursor-pointer ${
                (state === NavEnum.GAME &&
                  "left-[7.3%] md:left-[14.5%] lg:left-[8.9%] xl:left-[11.2%] 2xl:left-[12.7%]") ||
                (state === NavEnum.TUTORIAL &&
                  "left-[31%] lg:left-[30%] xl:left-[32%] 2xl:left-[33.4%]") ||
                (state === NavEnum.GAMEFLOW &&
                  "left-[48.4%] lg:left-[51.9%] xl:left-[53.5%] 2xl:left-[54.6%]") ||
                (state === NavEnum.ABOUT &&
                  "left-[70.3%] lg:left-[78.1%] xl:left-[78.7%] 2xl:left-[78.8%]")
              }`}
              onClick={() => {
                state === NavEnum.TUTORIAL && onOpen();
              }}
            >
              <span className="invisible group-hover:visible">{state}</span>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Navbar;
