import React, { useEffect, useRef, useState } from "react";
import { BsFillFileArrowUpFill } from "react-icons/bs";
import { Link, animateScroll as scroll } from "react-scroll";

const ScrollUp = () => {
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 560) setIsShow(true);
      else setIsShow(false);
    });
  }, []);

  return (
    <section id="SCROLL_UP">
      <Link
        to={"NAVBAR"}
        spy={true}
        smooth={true}
        offset={-100}
        duration={1000}
      >
        <button
          className={`fixed right-5 sm:right-10 -bottom-20 z-50 transition-all duration-1000 animate-bounce ${
            isShow && "!bottom-5 sm:!bottom-10"
          }`}
        >
          <BsFillFileArrowUpFill size={34} />
        </button>
      </Link>
    </section>
  );
};

export default ScrollUp;
